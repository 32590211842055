.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  backdrop-filter: blur(25px);
}

.login-panel {
  align-self: center;
  width: 400px;
  padding-top: 48px;
  padding-bottom: 64px;
  background-color: #ffffff;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
}

.login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
